<template>
  <div>
    <div class="bg-white p-3 rounded shadow-sm">
      <h2 class="mb-4">Laporan Stok</h2>
      <b-form @submit.prevent="filterTable" class="my-3 border rounded p-3">
        <b-row class="align-items-center">
          <b-col md="5">
            <b-form-group label="Start Date" label-for="startDate">
              <b-form-input
                type="date"
                id="startDate"
                v-model="filter.startDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="End Date" label-for="endDate">
              <b-form-input type="date" id="endDate" v-model="filter.endDate" />
            </b-form-group>
          </b-col>
          <b-col md="2" class="mt-3">
            <b-button type="submit" variant="primary" :disabled="isBusy" block>
              <b-spinner v-if="isBusy" small />
              Tampilkan</b-button
            >
          </b-col>
        </b-row>
      </b-form>
      <p v-if="items" class="ml-1">Periode: {{ items.Periode }}</p>
      <div v-if="items">
        <div v-for="(item, index) in tableItems" :key="index">
          <div class="mb-3 rounded border">
            <div
              class="d-flex justify-content-between align-items-center bg-light rounded border px-3"
              style="padding-top: 0.5rem; padding-bottom: 0.5rem"
            >
              <div>
                <h4>{{ item.name }}</h4>
                <p class="m-0">
                  Kode Barang:
                  <strong> {{ item.item_code }}</strong>
                </p>
                <p class="m-0">Selisih: {{ item.stock_remaining }}</p>
              </div>
              <b-button variant="success" v-b-toggle="'collapse-' + index">
                Expand
              </b-button>
            </div>
            <b-collapse
              :id="'collapse-' + index"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-row>
                  <b-col md="12" lg="6">
                    <label>Total Barang Masuk: {{ item.total_incoming }}</label>
                    <b-table
                      striped
                      hover
                      responsive
                      stacked="md"
                      :items="item.history.incoming"
                      :fields="incomingFields"
                    />
                  </b-col>
                  <b-col md="12" lg="6">
                    <label
                      >Total Barang Keluar: {{ item.total_outgoing }}</label
                    >
                    <b-table
                      striped
                      hover
                      responsive
                      stacked="md"
                      :items="item.history.outgoing"
                      :fields="outgoingFields"
                    >
                      <template #cell(status)="row">
                        <b-badge
                          :variant="
                            row.item.status === 'outgoing'
                              ? 'warning'
                              : 'success'
                          "
                          >{{ row.item.status }}</b-badge
                        >
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BCardBody,
  BCollapse,
  BTable,
  BBadge,
} from "bootstrap-vue";
import stockAPI from "../../../../../api/stock/stockAPI";

const filter = ref({
  startDate: "",
  endDate: "",
});

const isBusy = ref(false);
const items = ref(null);
const incomingFields = ref([
  { key: "date", label: "Date" },
  { key: "batch", label: "Batch" },
  { key: "incoming_stock", label: "Total" },
]);

const outgoingFields = ref([
  { key: "date", label: "Date" },
  { key: "nota", label: "Nota" },
  { key: "batch", label: "Batch" },
  { key: "total", label: "Total" },
  { key: "status", label: "Status" },
]);

const tableItems = computed(() => {
  if (!items.value) return [];
  return Object.entries(items.value)
    .map(([name, data]) => {
      if (name !== "Periode") {
        return { name, ...data };
      }
    })
    .filter(Boolean);
});

async function filterTable() {
  try {
    isBusy.value = true;
    const response = await stockAPI.getReport({
      start_date: filter.value.startDate,
      end_date: filter.value.endDate,
    });
    items.value = response.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    isBusy.value = false;
  }
}
</script>
